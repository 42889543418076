//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const directionValidator = direction => ["vertical", "horizontal"].includes(direction);
const sizeValidator = size => ["small", "medium", "large"].includes(size);
export default {
  props: {
    direction: {
      type: String,
      default: "horizontal",
      validator: v => directionValidator(v)
    },
    size: {
      type: String,
      default: "small",
      validator: v => sizeValidator(v)
    },
    stepperItems: {
      type: Array,
      default: () => [],
      validator: v => v.every(lv => Object.keys(lv).includes("selected"))
    }
  },
  computed: {
    computeClasses() {
      return ["stepper", "stepper--" + this.size];
    },
    computeDirection() {
      return this.direction == "vertical" ? "stepper__lists--vertical" : "stepper__lists";
    }
  }
};