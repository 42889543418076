//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from "vuex";
import { createCertification, createCertificationFee, updateCertification, updateCertificationFee, deleteCertification, deleteCertificationFee } from "@/lib/polkadotProvider/command/labs/certifications";
import serviceHandler from "@/mixins/serviceHandler";
import Dialog from "@/components/Dialog";
import Button from "@/components/Button";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "Certification",
  components: {
    Dialog,
    Button,
    DialogErrorBalance
  },
  mixins: [serviceHandler],
  data: () => ({
    certificationInfo: {
      title: "",
      issuer: "",
      month: "",
      year: "",
      description: "",
      supportingDocument: ""
    },
    certId: "",
    // for update certification
    certSupportingDocumentsUrl: "",
    selectMonths: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    certificationDialog: false,
    isUploading: false,
    isEditCertificationDialog: false,
    files: [],
    confirmDeleteDialog: false,
    certificationData: null,
    fee: "",
    isDeleting: false,
    isShowError: false
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      labAccount: "substrate/labAccount",
      isLabAccountExist: "substrate/isLabAccountExist"
    }),
    ...mapState({
      web3: state => state.metamask.web3,
      lastEventData: state => state.substrate.lastEventData
    }),
    selectYears() {
      const years = [];
      const thisYear = new Date().getFullYear();
      for (let i = thisYear; i >= 2000; i--) {
        years.push(String(i));
      }
      return years;
    },
    // supportingDocumentsRules(){
    //   return [
    //     file => !file || file.size <= 2_000_000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)"
    //   ]
    // },

    titleRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 50 || "This field only allows 50 characters", englishAlphabet];
    },
    issuerRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 100 || "This field only allows 100 characters", englishAlphabet];
    },
    monthRules() {
      return [val => !!val || "This field is required"];
    },
    yearRules() {
      return [val => !!val || "This field is required"];
    },
    descriptionRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 255 || "This field only allows 255 characters", englishAlphabet];
    }
  },
  watch: {
    certificationInfo: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        if (this.isEditCertificationDialog) {
          await this.getUpdateCertificationFee();
          return;
        }
        await this.getCreateCertificationFee();
      }, 500)
    },
    lastEventData() {
      if (this.lastEventData) {
        const dataEvent = JSON.parse(this.lastEventData.data.toString());
        if (this.lastEventData.method === "CertificationDeleted") {
          if (dataEvent[1] === this.pair.address) {
            this.isDeleting = false;
            this.confirmDeleteDialog = false;
          }
        }
      }
    }
  },
  methods: {
    openCertificationDialog() {
      this.certificationDialog = true;
    },
    closeCertificationDialog() {
      this.isLoading = false;
      this.certId = "";
      this.certificationDialog = false;
      this.isEditCertificationDialog = false;
      this.$refs.certificationForm.reset();
    },
    async submitCertification() {
      if (!this.certId) {
        await this.addCertification();
        return;
      }
      await this.updateCertification();
    },
    async getCreateCertificationFee() {
      this.certificationInfo.supportingDocument = this.certSupportingDocumentsUrl;
      const fee = await createCertificationFee(this.api, this.pair, this.certificationInfo);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async getUpdateCertificationFee() {
      const fee = await updateCertificationFee(this.api, this.pair, this.certId, this.certificationInfo);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async getDeleteCertificationFee() {
      const fee = await deleteCertificationFee(this.api, this.pair, this.certId);
      this.fee = this.web3.utils.fromWei(String(fee.partialFee), "ether");
    },
    async addCertification() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      try {
        this.certificationInfo.supportingDocument = this.certSupportingDocumentsUrl;
        this.isLoading = true;
        await this.dispatch(createCertification, this.api, this.pair, this.certificationInfo, () => {
          this.closeCertificationDialog();
        });
      } catch (error) {
        this.closeCertificationDialog();
        if (error.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async editCertification(cert) {
      const {
        title,
        issuer,
        month,
        year,
        description,
        supportingDocument
      } = cert.info;
      this.certId = cert.id;
      this.certificationInfo = {
        title,
        issuer,
        month,
        year,
        description,
        supportingDocument
      };
      const res = await fetch(this.certificationInfo.supportingDocument);
      const blob = await res.blob(); // Gets the response and returns it as a blob
      const type = blob.type;
      const file = new File([blob], this.certificationInfo.supportingDocument.substring(21), {
        type
      });
      this.files = file;
      this.certificationDialog = true;
      this.isEditCertificationDialog = true;
    },
    async updateCertification() {
      if (!this.$refs.certificationForm.validate()) {
        return;
      }
      try {
        if (this.certSupportingDocumentsUrl) {
          this.certificationInfo.supportingDocument = this.certSupportingDocumentsUrl;
        }
        await this.dispatch(updateCertification, this.api, this.pair, this.certId, this.certificationInfo, () => {
          this.closeCertificationDialog();
        });
      } catch (error) {
        this.closeCertificationDialog();
        this.isLoading = false;
        if (error.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async deleteCertification(cert) {
      this.certId = cert.id;
      this.getDeleteCertificationFee();
      this.confirmDeleteDialog = true;
      this.certificationData = cert;
    },
    async confirmDelete() {
      try {
        this.isDeleting = true;
        await this.dispatch(deleteCertification, this.api, this.pair, this.certId);
      } catch (error) {
        this.isDeleting = false;
        if (error.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isConfirmDeleteDialog = false;
          this.isShowError = true;
        }
      }
    },
    async fileUploadEventListener(file) {
      this.certSupportingDocumentsUrl = "";
      if (file && file.name) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        this.isLoading = true;
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.certSupportingDocumentsUrl = link;
        this.isUploading = false;
        this.isLoading = false;
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    closeDialog() {
      this.isLoading = false;
      this.isDeleting = false;
      this.isShowError = false;
      this.showDeletePrompt = false;
    }
  }
};