//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { registerLab, registerLabFee } from "@/lib/polkadotProvider/command/labs";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import Certification from "./Certification";
import Stepper from "./Stepper";
import { getLocations, getStates, getCities } from "@/lib/api";
import serviceHandler from "@/lib/serviceHandler";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { u8aToHex } from "@polkadot/util";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "LabRegistration",
  mixins: [serviceHandler],
  components: {
    Certification,
    Stepper,
    DialogErrorBalance
  },
  async mounted() {
    await this.getCountries();
    await this.setData();
  },
  data: () => ({
    document: {
      name: "",
      email: "",
      address: "",
      phoneNumber: "",
      website: "",
      country: "",
      region: "",
      city: ""
    },
    boxPublicKey: null,
    countries: [],
    cities: [],
    states: [],
    phoneCode: "",
    countryName: "",
    stateName: "",
    noState: false,
    noCity: false,
    files: null,
    isLoading: false,
    isUploading: false,
    fee: 0,
    stepperItems: [{
      name: "Lab Information",
      selected: false
    }, {
      name: "Lab Services",
      selected: false
    }],
    isShowError: false
  }),
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      labAccount: "substrate/labAccount",
      isLabAccountExist: "substrate/isLabAccountExist"
    }),
    ...mapState({
      web3: state => state.metamask.web3,
      mnemonicData: state => state.substrate.mnemonicData
    }),
    citiesSelection() {
      return this.cities.filter(c => c.country == this.document.country).map(c => ({
        value: c.city,
        text: c.city,
        country: c.country
      }));
    },
    selectedLab() {
      if (!this.labAccount) {
        return;
      }
      return this.labs.filter(l => l.labAccount == this.labAccount)[0];
    },
    emailRules() {
      return [val => !!val || "This field is required", val => /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(val) || "Email is invalid. It should contain @ followed by a domain", val => val && val.length <= 255 || "This field only allows 255 characters", val => val && /^[A-Za-z0-9 ]?[A-Za-z0-9@. ]+$/.test(val) || "This field only allows Alphabetic characters."];
    },
    nameRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 100 || "This field only allows 100 characters", englishAlphabet];
    },
    addressRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 255 || "This field only allows 255 characters", englishAlphabet];
    },
    phoneNumberRules() {
      return [val => !!val || "This field is required", val => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/.test(val) || "This field can only contain number"];
    },
    websiteRules() {
      return [val => !!val || "This field is required", englishAlphabet, val => val && val.length <= 255 || "This field only allows 255 characters", val => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/.test(val) || "Website is invalid. It should contain protocol (https://) followed by a domain"];
    },
    fileInputRules() {
      return [value => !!value || "This field is required", value => value && value.size < 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)"];
    }
  },
  watch: {
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getRegisterLabFee();
      }, 500)
    }
  },
  async created() {
    if (this.mnemonicData) this.initialData();
  },
  methods: {
    initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.boxPublicKey = u8aToHex(cred.boxKeyPair.publicKey);
    },
    gotoServicesPage() {
      this.$router.push({
        name: "lab-registration-services"
      });
    },
    async setData() {
      if (this.isLabAccountExist) {
        this.labName = this.labAccount.info.name;
        this.email = this.labAccount.info.email;
        this.address = this.labAccount.info.address;
        this.phoneNumber = this.labAccount.info.phoneNumber;
        this.website = this.labAccount.info.website;
        this.document.country = this.labAccount.info.country;
        await this.onCountryChange(this.document.country);
        this.document.region = this.labAccount.info.region;
        await this.onStateChange(this.labAccount.info.region); // Region means the state, backend response got region instead state
        this.document.city = this.labAccount.info.city;
        await this.onCityChange({
          name: this.labAccount.info.city
        });
        this.imageUrl = this.labAccount.info.profileImage;
        const res = await fetch(this.imageUrl);
        const blob = await res.blob(); // Gets the response and returns it as a blob
        const file = new File([blob], this.imageUrl.substring(21), {
          type: "image/jpeg"
        });
        this.files = file;
      }
    },
    setLabAccount(labAccount) {
      this.$store.state.substrate.labAccount = labAccount;
      this.$store.state.substrate.isLabAccountExist = true;
    },
    async getCountries() {
      const {
        data: {
          data
        }
      } = await this.dispatch(getLocations);
      this.countries = data;
    },
    async onCountryChange(selectedCountry) {
      var _selectedCountry$iso, _selectedCountry$iso2, _selectedCountry$phon3;
      this.states = [];
      this.cities = [];
      this.document.region = "";
      this.document.city = "";
      this.phoneCode = "";
      const {
        data: {
          data
        }
      } = await this.dispatch(getStates, (_selectedCountry$iso = selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.iso2) !== null && _selectedCountry$iso !== void 0 ? _selectedCountry$iso : selectedCountry);
      if (data.length < 1) {
        var _selectedCountry$phon, _selectedCountry$phon2;
        this.states.push(selectedCountry.name);
        this.noState = true;
        this.countryName = selectedCountry.name;
        this.document.country = selectedCountry;
        this.phoneCode = (_selectedCountry$phon = selectedCountry.phone_code) !== null && _selectedCountry$phon !== void 0 ? _selectedCountry$phon : null;
        this.document.phoneCode = (_selectedCountry$phon2 = selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.phone_code) !== null && _selectedCountry$phon2 !== void 0 ? _selectedCountry$phon2 : null;
        return;
      }
      this.states = data;
      this.document.country = (_selectedCountry$iso2 = selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.iso2) !== null && _selectedCountry$iso2 !== void 0 ? _selectedCountry$iso2 : selectedCountry;
      this.document.phoneCode = (_selectedCountry$phon3 = selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.phone_code) !== null && _selectedCountry$phon3 !== void 0 ? _selectedCountry$phon3 : null;
      this.noState = false;
    },
    async onPhoneCodeChange(selectedCountry) {
      await this.onCountryChange(selectedCountry);
    },
    async onStateChange(selectedState) {
      this.document.region = "";
      this.document.city = "";
      this.noCity = false;
      if (this.noState) {
        this.document.region = this.countryName;
        this.cities.push(this.countryName);
      }
      const {
        data: {
          data
        }
      } = await this.dispatch(getCities, this.document.country, selectedState);
      if (data.length < 1) {
        this.noCity = true;
        const stateName = this.states.find(state => state.state_code === selectedState).name;
        this.cities.push(stateName);
        this.document.region = selectedState;
        return;
      }
      this.cities = data;
      this.document.region = selectedState;
    },
    onCityChange(_ref) {
      let {
        name
      } = _ref;
      this.document.city = "";
      if (this.noState) {
        this.document.city = this.countryName;
        return;
      }
      if (this.noCity) {
        this.document.city = this.cities[0];
        return;
      }
      this.document.city = name;
    },
    async registerLab() {
      if (!this.validating()) {
        return;
      }
      try {
        const {
          name,
          email,
          address,
          phoneNumber,
          website,
          country,
          city,
          region
        } = this.document;
        this.isLoading = true;
        await this.fileUploadEventListener(this.files);
        await registerLab(this.api, this.pair, {
          boxPublicKey: this.boxPublicKey,
          name,
          email,
          address,
          phoneNumber,
          website,
          country,
          region,
          city,
          profileImage: this.imageUrl
        }, async () => {
          const labAccount = {
            accountId: this.pair.address,
            services: [],
            certifications: [],
            info: {
              boxPublicKey: this.boxPublicKey,
              name,
              email,
              address,
              country,
              city: city,
              profileImage: this.imageUrl
            }
          };
          this.setLabAccount(labAccount);
          this.stepperItems = [{
            name: "Lab Information",
            selected: true
          }, {
            name: "Lab Services",
            selected: false
          }];
        });
      } catch (e) {
        console.error(e);
        if (e.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
      }
    },
    async fileUploadEventListener(file) {
      if (file && file.name) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        this.isUploading = true;
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.imageUrl = link;
        this.isUploading = false;
      } else {
        this.files = null;
        this.imageUrl = "";
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    validating() {
      if (this.document.name == "" || this.document.email == "" || this.document.address == "" || this.document.country == "" || this.document.city == "" || this.document.region == "" || this.files == null) {
        this.$refs.labForm.validate();
        return false;
      }
      return true;
    },
    async getRegisterLabFee() {
      const {
        name,
        email,
        address,
        phoneNumber,
        website,
        country,
        city,
        region
      } = this.document;
      const txWeight = await registerLabFee(this.api, this.pair, {
        boxPublicKey: this.boxPublicKey,
        name,
        email,
        address,
        phoneNumber,
        website,
        country,
        region,
        city,
        profileImage: this.imageUrl
      });
      this.fee = this.web3.utils.fromWei(String(txWeight.partialFee), "ether");
    },
    closeDialog() {
      this.isShowError = false;
      this.isLoading = false;
    }
  }
};