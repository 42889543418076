//
//
//
//
//
//
//
//
//
//
//
//

import Stepper from "@/components/Stepper";
export default {
  name: "StepperRegistration",
  components: {
    Stepper
  },
  props: {
    stepperDescription: String,
    stepperItems: Array
  }
};